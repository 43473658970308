ul {
    list-style-type: none;
}

body {
    padding-top: 50px;
}

.wrapper {
    max-width: 70rem;
    margin: 0 auto;
    padding: 0 1.5rem;
}

.profile {
    max-width: 350px;
    min-width: 10px;
    padding-bottom: 3rem!important;
}

.avatar {
    border-radius: 30%;
    width: 100%;
}

.home .bio {
    max-width: 760px;
}

a.home {
    background-color: #212529;
    color: white;
    text-decoration: none;
    transition: background-color 1s ease-out;
}

.mtu:hover {
    background-color: #d90725;
    cursor: pointer;
}

.stripe:hover {
    background-color: #6772e5;
    cursor: pointer;
}

.trellix:hover {
    background-color: #2814ff;
    cursor: pointer;
}

.fa-skills {
    color: #f5f5f5;
}

.btn-group {
    min-width: 232px;
    padding-top: 84px;
}

.border-transparent {
    border: transparent;
}

.fa-icon {
    transition: color 1s cubic-bezier(.18, .89, .32, 1.28);
    font-size: 14vw;
}

.fa-icon:hover {
    color: #e08e25;
    cursor: pointer;
}

.icon-link {
    font-weight: 600;
    width: 12vw;
    height: 12vw;
    max-height: 147px;
    max-width: 147px;
    font-size: 7vw;
    border-radius: 10%;
    color: #ffffff;
    background-color: #212529;
    transition: background-color 1s cubic-bezier(.18, .89, .32, 1.28);
}

.icon-link:hover {
    background-color: #e08e25;
    cursor: pointer;
}

.icon-wrap {
    max-height: 314px;
}

.skew-y {
    transform: skewY(-11deg);
    overflow: hidden;
}

.skew-y>* {
    transform: skewY(5.5deg);
}

.anti-skew-y {
    transform: skewY(5.5deg);
}

.blue-bg {
    background-color: #a7bcd7;
}

.blue-bg-transparent {
    background-color: rgba(167, 188, 215, 0.7);
}

.blue-bg-light-transparent {
    background-color: rgba(167, 188, 215, 0.9);
}

.orange-bg {
    background-color: #e08e25;
}

.orange-bg-transparent {
    background-color: rgba(224, 142, 37, 0.6);
}

.orange-bg-light-transparent {
    background-color: rgba(224, 142, 37, 0.5);
}

.nav-link {
    border-bottom: .25rem solid transparent;
}

.nav-link:hover,
.nav-link:focus {
    border-bottom-color: rgba(224, 142, 37, .25);
}

.nav .active {
    border-bottom-color: #e08e25;
}

.img-fluid {
    transition: transform 1s;
}

.media-project {
    max-height: 166px;
}

.description {
    border: 2px solid #e08e25 !important;
}

.about-list::marker {
    content: "»";
    font-size: 1em;
    color: #e08e25;
}

.about-list:nth-child(odd)::marker {
    color: #85a5de;
}

.about-list {
    padding-left: 20px;
}

.filter-container {
    transition: height 1s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.date-fit {
    font-size: 1.45em;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 10px;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgba(167, 188, 215, 0.6)), color-stop(0.86, rgba(224, 142, 37, 0.6)));
}

@media screen and (min-width: 1200px) {
    .icon-link {
        font-size: 96px;
    }
    .fa-icon {
        font-size: 168px;
    }
}

@media (max-width: 991px) {
    .profile {
        padding-bottom: 0rem!important;
    }
}

@media only screen and (max-width: 649px) {
    body {
        padding-top: 50px;
    }
    .box {
        margin-right: 40px;
        margin-left: 40px;
    }
    .btn-group {
        padding-top: 10px;
    }
    .name {
        visibility: hidden;
        width: 0;
        height: 0;
        margin: 0;
        padding: 0;
    }
}

@media (min-width: 650px) {
    .me-md-auto {
        margin-right: auto!important;
        margin-bottom: 0!important;
    }
    .nav-link {
        margin-bottom: 0!important;
    }
}

@media (max-width: 455px) {
    .icons {
        margin-right: auto;
        margin-left: auto;
        padding-right: 0;
        padding-left: 0;
    }
    .icons .a {
        padding-right: 0;
        padding-left: 0;
    }
    .wrapper {
        padding: 0 1rem;
    }
}