.btn-outline-primary {
    color: #85a5de;
    background-color: transparent;
    background-image: none;
    border-color: #85a5de;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #85a5de;
    border-color: #85a5de;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
    box-shadow: 0 0 0 .2rem rgba(133, 165, 222, 0.5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #f14e4e;
    border-color: #f14e4e;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #85a5de;
    border-color: #85a5de;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(133, 165, 222, 0.5)
}